<template>
  <div class="main-page">
    <div class="container mt-md-2 pt-md-5">
      <app-bar
        title="Ringkasan Pemesanan"
        :previousLink="{
          name: 'ProfilOrder'
        }" />
      <div>
        <div v-if="loading.detail" class="item-content">
          <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
            <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="mb-4">
                <img src="@/assets/icon/empty.png" alt="">
              </div>
              <div class="fm-poppins text-title-active fw-600 fs-32">
                Loading..
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="detail.length === 0" class="item-content">
          <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
            <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="bd-highlight mb-4">
                <img src="@/assets/icon/not-found.png" alt="">
              </div>
              <div class="bd-highlight">
                <div class="fm-poppins text-title-active fw-600 fs-32">Tidak Ada Hasil</div>
              </div>
              <div class="bd-highlight w-75 text-center">
                <p class="text-nunito text-body-light fw-400 fs-18">Sepertinya kami tidak dapat menemukan data pesanan Anda.</p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-md-8 pt-4">
            <div class="row">
              <div class="d-flex flex-column mb-3">
                <div class="bd-highlight contact">
                  <p class="text-gray-2 fm-poppins fst-normal fs-18 fw-600 lh-27">Kontak Pemesanan</p>
                  <div class="card-info d-flex flex-row align-items-center py-2 px-2 group-profile">

                    <img v-if="user.foto" class="px-2" :src="user.foto" :alt="user.pas_nama">
                    <img v-else class="px-2" src="@/assets/user-placeholder.png" :alt="user.pas_nama">

                    <div class="d-flex flex-column p-2">
                      <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0 pt-1 pb-2 pe-2">{{ user.pas_nama }}</p>
                      <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-12 mb-0 py-1">{{ user.pas_hp }}</p>
                    </div>
                  </div>
                </div>
                <div class="bd-highlight pasient-list py-3">
                  <p class="text-gray-2 fm-poppins fst-normal fs-18 fw-600 lh-27">Daftar Pasien</p>
                  <div class="card-info d-flex flex-column p-3 mb-3">
                    <div
                    v-for="patient in detail.patients"
                    :key="patient"
                    class="bd-highlight d-flex flex-row align-items-center mb-1"
                    >
                      <img class="pe-2" src="@/assets/Icon.png" alt="">
                      <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0 pt-1 pb-2 pe-2">{{ patient.ap_nama }}</p>
                    </div>
                  </div>
                </div>
                <div class="bd-highlight summary-order py-2">
                  <p class="text-gray-2 fm-poppins fst-normal fs-18 fw-600 lh-27">Ringkasan Pemesanan</p>
                  <div class="card card-summary-order">
                      <div class="card-body pb-1">
                          <div class="group d-flex flex-column">
                              <div
                              v-for="service in detail.services"
                              :key="service"
                              class="bd-highlight d-flex flex-row justify-content-between align-items-center pt-2 pb-3"
                              >
                                  <div class="bd-highlight">
                                      <p class="fst-normal text-gray-3 fw-normal lh-19 fs-14 mb-0 fm-nunito">{{ service.nama }}</p>
                                  </div>
                                  <div class="bd-highlight">
                                      <p class="fst-normal text-gray-3 fw-bold lh-19 fs-14 mb-0 fm-nunito">
                                      Rp. {{ parseFloat(service.harga).toLocaleString('id-ID') }}
                                      </p>
                                  </div>
                              </div>

                              <div v-if="diskon > 0">
                                  <div class="divider">
                                      <hr class="border bg-gray-6">
                                  </div>
                                  <div
                                  class="bd-highlight d-flex flex-row justify-content-between align-items-center pt-2 pb-3"
                                  >
                                      <div class="bd-highlight">
                                          <p class="fst-normal text-gray-3 fw-normal lh-19 fs-14 mb-0 fm-nunito">Diskon</p>
                                      </div>
                                      <div class="bd-highlight">
                                          <p class="fst-normal text-gray-3 fw-bold lh-19 fs-14 mb-0 fm-nunito text-decoration-line-through">
                                          Rp. {{ parseFloat(diskon).toLocaleString('id-ID') }}
                                          </p>
                                      </div>
                                  </div>
                              </div>

                              <div
                              v-if="false"
                              class="bd-highlight d-flex flex-row justify-content-between align-items-center pt-2 pb-3"
                              >
                                  <div class="bd-highlight">
                                      <p class="fst-normal text-gray-3 fw-normal lh-19 fs-14 mb-0 fm-nunito">Ongkos Kirim</p>
                                  </div>
                                  <div class="bd-highlight">
                                      <p class="fst-normal text-gray-3 fw-bold lh-19 fs-14 mb-0 fm-nunito">
                                      Rp. 15.000
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-footer bg-gray-6 py-3 border-0">
                          <div class="d-flex flex-row align-items-center justify-content-between">
                              <div class="bd-highlight">
                                  <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-19 mb-0">Total Tagihan</p>
                              </div>
                              <div class="bd-highlight">
                                  <p class="text-gray-1 fm-poppins fst-normal fw-600 fs-18 lh-27 mb-0">
                                      Rp. {{ parseFloat(firstPatient.nominal).toLocaleString('id-ID') }}
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pt-4 pb-5 pb-md-0 mb-4 mb-md-0">
              <div class="card card-booking p-0 p-md-2 border-0">
                  <div class="card-body p-2 p-md-3">
                      <div class="d-flex flex-column">
                          <div class="bd-highlight">
                              <div class="booking-id d-flex flex-row justify-content-between align-items-center px-3 py-2">
                                  <div class="bd-highlight">
                                      <div class="d-flex flex-row align-items-center">
                                          <div class="bd-highlight">
                                              <img width="20" height="20" src="@/assets/tagar-icon.png" alt="">
                                          </div>
                                          <div class="bd-highlight px-2">
                                              <p class="text-gray-3 fm-poppins fst-normal fw-500 fs-12 lh-18 mb-0 pt-1">Booking ID</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="bd-highlight">
                                      <p class="text-gray-1 fm-nunito fst-normal fw-bold fs-14 lh-14 mb-0">{{ $route.params.id_order }}</p>
                                  </div>
                              </div>
                          </div>

                          <div class="bd-highlight py-3">
                              <div v-if="firstPatient.status_bayar === 'Sudah Dibayar'" class="note bg-success-disable p-3">
                                  <p class="text-success fm-nunito fw-600 fst-normal fs-14 lh-19 mb-0">
                                      Pesanan telah dibayar, Semoga sehat selalu.
                                  </p>
                              </div>
                              <div v-else class="note bg-blue-soft-pastel p-3">
                                  <p class="text-primary fm-nunito fw-600 fst-normal fs-14 lh-19 mb-0">
                                      {{ (firstPatient.pembayaran === 'Bayar Offline') ? 'Silahkan periksa detail pemesanan Anda lalu lakukan Pembayaran saat di klinik.':'Silahkan periksa detail pemesanan Anda lalu klik Lanjutkan ke Pembayaran.' }}
                                  </p>
                              </div>
                          </div>

                          <div
                          v-if="firstPatient.status_bayar === 'Sudah Dibayar' || firstPatient.pembayaran === 'Bayar Offline'"
                          class="bd-highlight d-flex flex-column align-items-center"
                          >
                              <img
                              :src="'https://api.qrserver.com/v1/create-qr-code/?color=007A98&data='+$route.params.id_order"
                              alt="Qr Code"
                              >
                          </div>

                          <div v-if="firstPatient.status_bayar === 'Belum Dibayar' && firstPatient.pembayaran !== 'Bayar Offline'">
                          <div class="note bg-danger p-3 mb-0 mb-md-3">
                              <p class="text-white fm-nunito fw-bolder fst-normal fs-14 lh-19 mb-0">
                                  PERINGATAN: Uang yang sudah dibayarkan tidak dapat ditarik kembali !!!
                              </p>
                          </div>

                          <div class="bottom-bar p-3 p-md-0">
                              <button
                                  class="btn btn-warning btn-next w-100 pt-2 pb-2 text-white fm-nunito fst-normal fw-600 fs-14 lh-19 m-0"
                                  role="button"
                                  @click="pay"
                              >
                                  Lanjutkan ke Pembayaran <i class="ri-arrow-right-line align-middle"></i>
                              </button>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, computed, onMounted } from 'vue'
import { getDetail, detail, loading } from '@/core/profile'
import { user } from '@/core/auth'
import AppBar from '@/components/AppBar.vue'

export default {
  components: {
    AppBar
  },
  setup () {
    const errorMessage = ref('')
    const route = useRoute()

    onMounted(async () => {
      try {
        await getDetail({
          kode: route.params.id_order
        })
      } catch (error) {
        errorMessage.value = error.response.data.message
      }
    })

    const firstPatient = computed(() => {
      if (detail.value.patients) {
        return detail.value.patients[0]
      }
      return {}
    })

    const diskon = computed(() => {
      if (detail.value.patients && detail.value.services) {
        return detail.value.services.reduce((acc, item) => acc + parseInt(item.harga), 0) - firstPatient.value.nominal
      }
      return 0
    })

    // const ongkir = computed(() => {
    //   if (diskon !== 0) {
    //     return
    //   }
    //   return 0
    // })

    const pay = () => {
      const token = firstPatient.value.token

      if (!token) {
        errorMessage.value = 'Data token tidak ditemukan'
        return
      }

      // window.snap.pay(token, {
      //   onSuccess: () => {
      //     getDetail({
      //       kode: route.params.id_order
      //     })
      //   }
      // })

      window.getSnap().then(snap => {
        snap.pay(token, {
          onSuccess: () => {
            getDetail({
              kode: route.params.id_order
            })
          }
        })
      })
    }

    return {
      detail,
      user,
      loading,
      diskon,
      pay,
      errorMessage,
      firstPatient
    }
  }
}
</script>

<style lang="scss" scoped>
.card-info {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-summary-order {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
}

.card-booking {
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.booking-id {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
}

.note {
    border-radius: 10px;
}

.btn-next {
    border-radius: 8px;
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgb(240, 240, 240);
  }
}

.group-profile {
    img {
        width: 4.25rem;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: .4rem;
    }
}
</style>
